// TotalBalance.js
import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

const PrizeBalanceText = ({balance}) => {
    const theme = useTheme();
    return (
        <div style={{ display: 'flex', alignItems: 'center', fontFamily: theme.fontFamily }}>
          <span style={{ fontSize: '48px', fontWeight: 700, lineHeight: '56px', letterSpacing: '0em', textAlign: 'left', color: theme.textStrong }}>
            {balance}
          </span>
          <span style={{ fontSize: '20px', fontWeight: 400, lineHeight: '24px', letterSpacing: '-1px', textAlign: 'left', color: '#B2B2B2' }}>
            HBAR
          </span>
        </div>
      );
};

export default PrizeBalanceText;
