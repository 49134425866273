import React, { useEffect, useState } from "react";
import { getTotalBalance } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import EstimatedPrizeBalanceText from "../weekly-reward/EstimatedPrizeBalanceText";

const EstimatedPrizeBalance = ({ contractAddress, refreshData }) => {
  const [balance, setBalance] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchTotalBalance = async () => {
      const result = await getTotalBalance(contractAddress);
      setBalance(result);
    };

    fetchTotalBalance();
  }, [contractAddress, refreshData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <p
        style={{
          fontFamily: theme.fontFamily,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0px",
          textAlign: "left",
          color: theme.textDefault,
          marginBottom: 0,
        }}
      >
        Estimated Final Prize Total
        <Tooltip
          title="This is the estimated reward for the week based on the Pool Total and a Hedera yield of 2.5%"
          arrow
        >
          <FontAwesomeIcon
            style={{ marginLeft: "8px" }}
            icon={faInfoCircle}
            color="#94C008"
          />
        </Tooltip>
      </p>

      <div style={{ marginTop: "8px" }}>
        {balance !== null ? (
          <EstimatedPrizeBalanceText
            balance={`${((Number(balance) * 0.025) / 52).toFixed(2)}`}
          />
        ) : (
          "Loading..."
        )}
      </div>
    </div>
  );
};

export default EstimatedPrizeBalance;
