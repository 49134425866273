// TotalBalance.js
import React from "react";
import { useTheme } from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import TimeCountDown from "../time-count-down/TimeCountDownDisplay";
import Tooltip from "@mui/material/Tooltip";

const RewardDistributionDisplay = ({ contractAddress }) => {
  const theme = useTheme();
  return (
    <div>
      <p
        style={{
          fontFamily: theme.fontFamily,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0px",
          textAlign: "left",
          color: theme.textDefault,
          marginBottom: 0, // Adjust margin-bottom as needed
        }}
      >
        Next Reward Distribution
        <Tooltip
          title="This resets every Friday at 10:23:50 PM (UTC)"
          arrow
        >
        <FontAwesomeIcon
          style={{ marginLeft: "8px" }}
          icon={faInfoCircle}
          color="#94C008"
        />
        </Tooltip>
      </p>
      <TimeCountDown contractAddress={contractAddress} />
    </div>
  );
};

export default RewardDistributionDisplay;
