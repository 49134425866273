// TotalBalance.js
import React, { useEffect, useState } from "react";
import { getPrizeBalance } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";
import PrizeBalanceText from "../weekly-reward/PrizeBalanceText";

const PrizeBalance = ({ contractAddress, refreshData }) => {
  const [balance, setBalance] = useState(null);
  const theme = useTheme();
  useEffect(() => {
    const fetchTotalBalance = async () => {
      const result = await getPrizeBalance(contractAddress);
      setBalance(result);
    };

    fetchTotalBalance();
  }, [contractAddress, refreshData]);

  return (
    <div>
      <p
        style={{
          color: theme.textDefault,
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0px",
          textAlign: "left",
          fontFamily: theme.fontFamily
        }}
      >
        Current Prize Pool
      </p>
      <div style={{ color: theme.textStrong, fontFamily: theme.fontFamily, fontSize: '48px', fontWeight: 700, lineHeight: '56px', letterSpacing: '0em', textAlign: 'left'}}>
        {balance !== null ? <PrizeBalanceText balance={balance} /> : "Loading..."}
      </div>
     
    </div>
  );
};

export default PrizeBalance;
