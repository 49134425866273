// CardComponent.js
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import PrizeBalance from '../prize-balance/PrizeBalanceDisplay';
import EstimatedPrizeBalance from '../estimated-prize-balance/EstimatedPrizeBalanceDisplay';
import RewardDistributionDisplay from '../reward-distribution-display/RewardDistributionDisplay';

const WeeklyRewardDisplay = ({ contractAddress, refreshData }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        width: '330px',
        height: 'fit-content',
        backgroundColor: theme.cardBackground,
        borderRadius: '10px', // Adjust the radius as needed
        padding: '20px', // Adjust the padding as needed
        boxSizing: 'border-box',
        marginBottom: '30px',
        fontFamily: theme.fontFamily,
      }}
    >
      <h2 style={{ color: theme.textDefault, fontSize: '24px', fontWeight: 700, lineHeight: '28px', letterSpacing: '-1px', textAlign: 'left' }}>
        Weekly Reward
      </h2>
      <PrizeBalance contractAddress={contractAddress} refreshData={refreshData} />
      <EstimatedPrizeBalance contractAddress={contractAddress} refreshData={refreshData} />
      <RewardDistributionDisplay contractAddress={contractAddress} refreshData={refreshData} />
    </div>
  );
};

export default WeeklyRewardDisplay;
