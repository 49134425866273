// import React from 'react';
// import { useTheme } from '../../contexts/ThemeContext';

// const WinnerDisplayRow = ({ winnerAddress, winnerAmount }) => {
//   const theme = useTheme();
//   const columnStyle = {
//     fontFamily: 'Inter',
//     fontSize: '16px',
//     lineHeight: '24px',
//     letterSpacing: '0px',
//     textAlign: 'left',
//     color: '#EBEBEB',
//   };

//   const addressStyle = {
//     ...columnStyle,
//     fontWeight: 400,
//     fontFamily: theme.fontFamily,
//     marginRight: '8px', // Add right margin for spacing
//   };

//   const amountStyle = {
//     ...columnStyle,
//     fontFamily: theme.fontFamily,
//     fontWeight: 700,
//   };

//   return (
//     <div style={{ display: 'flex', marginBottom: '8px' }}>
//       <div style={addressStyle}>{winnerAddress}</div>
//       <div style={amountStyle}>{`${winnerAmount} HBAR`}</div>
//     </div>
//   );
// };

// export default WinnerDisplayRow;
import React from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const WinnerDisplayRow = ({ winnerAddress, winnerAmount }) => {
  const theme = useTheme();
  const columnStyle = {
    fontFamily: theme.font,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#EBEBEB',
  };

  const addressStyle = {
    ...columnStyle,
    fontWeight: 400,
    maxWidth: '160px',
    fontFamily: theme.fontFamily,
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Display ellipsis for overflowed text
    whiteSpace: 'nowrap', // Prevent text from wrapping
  };

  const amountStyle = {
    ...columnStyle,
    fontFamily: theme.fontFamily,
    fontWeight: 700,
    textAlign: 'end'
  };

  return (
    <div style={{ display: 'flex',justifyContent: 'space-between', marginBottom: '8px' }}>
      <div title={winnerAddress} style={addressStyle}>
        {winnerAddress}
      </div>
      <div style={amountStyle}>{`${winnerAmount} HBAR`}</div>
    </div>
  );
};

export default WinnerDisplayRow;
