import "./App.css";
import HomePage from "./pages";
import { ThemeProvider } from './contexts/ThemeContext';

const theme = {
  cardBackground: '#262626',
  textStrong: '#FFFFFF',
  textDefault: '#EBEBEB',
  pageBackground: '#1D1D1D',
  primaryColor: '#007bff',
  secondaryColor: '#94C008',
  fontFamily: 'BNanoDisplay, sans-serif',
  // Add other theme properties as needed
};
function App() {
  return (
  <ThemeProvider theme={theme}>
    <HomePage></HomePage>
  </ThemeProvider>
  )
}

export default App;
