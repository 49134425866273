import axios from "axios";
import { Interface } from "ethers";
import {
  AccountId,
  ContractId,
  Hbar,
  TransactionId,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  EvmAddress,
  HbarUnit
} from "@hashgraph/sdk";
import { HashConnect } from "hashconnect";
import Web3 from "web3";
import { getLottoContractAddress } from "../utils/contract-utils";

const NETWORK = process.env.REACT_APP_NETWORK ?? "testnet";
const BASE_URL =
  NETWORK === "testnet"
    ? "https://testnet.mirrornode.hedera.com"
    : "https://mainnet-public.mirrornode.hedera.com";

const lottoAddress = getLottoContractAddress(); 

async function decodeEvent(eventName, log, topics) {
  const web3 = new Web3();
  let abi = [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "_user",
          type: "address",
        },
        {
          indexed: true,
          internalType: "uint256",
          name: "_timestamp",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "_tinybarWon",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "randomNum",
          type: "uint256",
        },
      ],
      name: "WinnerPicked",
      type: "event",
    },
  ];
  const eventAbi = abi.find(
    (event) => event.name === eventName && event.type === "event"
  );
  const decodedLog = web3.eth.abi.decodeLog(eventAbi.inputs, log, topics);
  return decodedLog;
}

function encodeFunctionCall(
  functionName,
  functionParameterType,
  functionParameterName,
  functionValue
) {
  // Define the contract ABI with the function you want to call
  const abi = [
    {
      name: functionName,
      type: "function",
      inputs: [
        {
          name: functionParameterName, // You can change the parameter name as needed
          type: functionParameterType, // Use the correct type for your parameter
        },
      ],
    },
  ];

  // Create an ethers.js Contract interface
  const iface = new Interface(abi);

  // Encode the function call
  const encodedFunctionCall = iface.encodeFunctionData(functionName, [
    functionValue,
  ]);

  return encodedFunctionCall;
}
// hederaService.js
export const getTotalBalance = async (contractAddress) => {
  try {
    // Replace this with the actual function name and parameters of your smart contract

    const apiUrl = `${BASE_URL}/api/v1/contracts/call`;

    const response = await axios.post(apiUrl, {
      block: "latest",
      data: "0x968ed600",
      to: contractAddress,
    });

    // The result should contain the value of the smart contract variable
    const contractValue = response.data.result;
    const resultDecimal = parseInt(contractValue.substring(2), 16);
    return String(resultDecimal / 100_000_000);
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getUserBalance = async (contractAddress, userAddress) => {
  try {
    // Replace this with the actual function name and parameters of your smart contract
    const encodedBytes = await encodeFunctionCall(
      "getUserBalance",
      "address",
      "user",
      userAddress
    );

    const apiUrl = `${BASE_URL}/api/v1/contracts/call`;

    const response = await axios.post(apiUrl, {
      block: "latest",
      data: encodedBytes,
      to: contractAddress,
    });

    // The result should contain the value of the smart contract variable
    const contractValue = response.data.result;
    const resultDecimal = parseInt(contractValue.substring(2), 16);
    return String(resultDecimal / 100_000_000);
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getPrizeBalance = async (contractAddress) => {
  try {
    const apiUrl = `${BASE_URL}/api/v1/accounts/${contractAddress}`;

    const response = await axios.get(apiUrl, {});

    const depositBalance = await getTotalBalance(contractAddress);

    const hbarBalance = response.data.balance.balance;
    const pendingPrize = response.data.pending_reward;
    const TotalVal = hbarBalance + pendingPrize;
    const prizeBalance = TotalVal / 100_000_000 - Number(depositBalance);
    const winnablePrizeBalance = prizeBalance * 0.8;
    return String(winnablePrizeBalance.toFixed(2));
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getStartTime = async (contractAddress) => {
  try {
    // Replace this with the actual function name and parameters of your smart contract

    const apiUrl = `${BASE_URL}/api/v1/contracts/call`;

    const response = await axios.post(apiUrl, {
      block: "latest",
      data: "0x0b97bc86",
      to: contractAddress,
    });

    // The result should contain the value of the smart contract variable
    const contractValue = response.data.result;
    const resultDecimal = parseInt(contractValue.substring(2), 16);
    // Create a new Date object using the milliseconds since epoch
    const date = new Date(resultDecimal * 1000);

    // Get the ISO string representation
    const isoDateString = date.toISOString();
    return isoDateString;
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getEndTime = async (contractAddress) => {
  try {
    // Replace this with the actual function name and parameters of your smart contract

    const apiUrl = `${BASE_URL}/api/v1/contracts/call`;

    const response = await axios.post(apiUrl, {
      block: "latest",
      data: "0xc24a0f8b",
      to: contractAddress,
    });

    // The result should contain the value of the smart contract variable
    const contractValue = response.data.result;
    const resultDecimal = parseInt(contractValue.substring(2), 16);
    // Create a new Date object using the milliseconds since epoch
    const date = new Date(resultDecimal * 1000);

    // Get the ISO string representation
    const isoDateString = date.toISOString();
    return isoDateString;
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getRemainingTime = async (contractAddress) => {
  try {
    const endTime = await getEndTime(contractAddress);

    const startDate = Date.now();
    const endDate = new Date(endTime);

    const timeDifferenceInMilliseconds = endDate - startDate;
    return Math.floor(timeDifferenceInMilliseconds / 1000);
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const getEvmAddress = async (accountId) => {
  try {
    const apiUrl = `${BASE_URL}/api/v1/accounts/${accountId}`;

    const response = await axios.get(apiUrl, {});

    return response.data.evm_address;
  } catch (e) {
    return `Error: ${e}`;
  }
};

export const PurchaseShares = async (HbarShareAmount, hashAccount) => {
  const fromId = hashAccount.accountId;
  const nodeId = [new AccountId(4)];
  const contractAddress = lottoAddress;
  const trxMemo = `Deposit ${HbarShareAmount} hbar for ${HbarShareAmount} shares, fully refundable`;
  try {
    const hashconnect = new HashConnect();
    await hashconnect.init(
      {
        name: "No Lose Lottery",
        description: "A fully liquid never lose lottery",
        icon: "",
      },
      NETWORK,
      true
    );

    const transactionId = TransactionId.generate(fromId);
    const contractId = ContractId.fromEvmAddress(0, 0, contractAddress);
    const contractExecuteTransaction = new ContractExecuteTransaction()
      .setContractId(contractId)
      .setGas(200000) // Set an appropriate gas limit for your function execution
      // Hashscan says it takes 66,273
      .setFunction(
        "buyEntries",
        new ContractFunctionParameters().addUint256(HbarShareAmount * 100_000_000)
      ) // Name of the function you want to call
      .setPayableAmount(new Hbar(HbarShareAmount * 100_000_000, HbarUnit.Tinybar))
      .setNodeAccountIds(nodeId)
      .setTransactionId(transactionId)
      .setTransactionMemo(trxMemo)
      .freeze();

    const txBytes = contractExecuteTransaction.toBytes();
    const transaction = {
      byteArray: txBytes,
      metadata: {
        accountToSign: fromId,
        returnTransaction: false,
      },
      topic: hashAccount.topic,
    };

    const txResponse = await hashconnect.sendTransaction(
      hashAccount.topic,
      transaction
    );

    if (!txResponse.success) throw new Error("Error Buying Shares.");
  } catch (e) {
    console.log(e);
  }
};

export const PickWinner = async (hashAccount) => {
  const fromId = hashAccount.accountId;
  const nodeId = [new AccountId(4)];
  const contractAddress = lottoAddress;
  const trxMemo = `Pick a Winner`;
  try {
    const hashconnect = new HashConnect();
    await hashconnect.init(
      {
        name: "No Lose Lottery",
        description: "A fully liquid never lose lottery",
        icon: "",
      },
      NETWORK,
      true
    );

    const transactionId = TransactionId.generate(fromId);
    const contractId = ContractId.fromEvmAddress(0, 0, contractAddress);
    const contractExecuteTransaction = new ContractExecuteTransaction()
      .setContractId(contractId)
      .setGas(200000) // Set an appropriate gas limit for your function execution
      // Hashscan says it takes 66,273
      .setFunction(
        "pickWinner",
      ) // Name of the function you want to call
      .setNodeAccountIds(nodeId)
      .setTransactionId(transactionId)
      .setTransactionMemo(trxMemo)
      .freeze();

    const txBytes = contractExecuteTransaction.toBytes();
    const transaction = {
      byteArray: txBytes,
      metadata: {
        accountToSign: fromId,
        returnTransaction: false,
      },
      topic: hashAccount.topic,
    };

    const txResponse = await hashconnect.sendTransaction(
      hashAccount.topic,
      transaction
    );

    if (!txResponse.success) throw new Error("Error Buying Shares.");
  } catch (e) {
    console.log(e);
  }
};

export const WithdrawShares = async (HbarShareAmount, hashAccount) => {
  const fromId = hashAccount.accountId;
  const nodeId = [new AccountId(4)];
  const contractAddress = lottoAddress;
  const trxMemo = `Withdraw ${HbarShareAmount} hbar`;
  try {
    const hashconnect = new HashConnect();
    await hashconnect.init(
      {
        name: "No Lose Lottery",
        description: "A fully liquid never lose lottery",
        icon: "",
      },
      NETWORK,
      true
    );

    const transactionId = TransactionId.generate(fromId);
    const contractId = ContractId.fromEvmAddress(0, 0, contractAddress);
    const contractExecuteTransaction = new ContractExecuteTransaction()
      .setContractId(contractId)
      .setGas(200000) // Set an appropriate gas limit for your function execution
      //Hashcan shows 74,734
      .setFunction(
        "redeemEntries",
        new ContractFunctionParameters().addUint256(HbarShareAmount * 100_000_000)
      ) // Name of the function you want to call
      .setNodeAccountIds(nodeId)
      .setTransactionId(transactionId)
      .setTransactionMemo(trxMemo)
      .freeze();

    const txBytes = contractExecuteTransaction.toBytes();
    const transaction = {
      byteArray: txBytes,
      metadata: {
        accountToSign: fromId,
        returnTransaction: false,
      },
      topic: hashAccount.topic,
    };

    const txResponse = await hashconnect.sendTransaction(
      hashAccount.topic,
      transaction
    );

    if (!txResponse.success) throw new Error("Error Redeeming Shares.");
  } catch (e) {
    console.log(e);
  }
};

export const getRecentWinners = async (contractAddress) => {
  try {
    let apiUrl = `${BASE_URL}/api/v1/contracts/${contractAddress}/results/logs`;

    let next = true;
    let logs = [];

    while (next) {
      const response = await axios.get(apiUrl, {});
      logs = logs.concat(response.data.logs);
      next = response.data.links.next ?? false;
      apiUrl = `${BASE_URL}${response.data.links.next}`;
    }

    const winnersLogs = await Promise.all(
      logs.map(async (log) => {
        try {
          const event = await decodeEvent(
            "WinnerPicked",
            log.data,
            log.topics.slice(1)
          );
          return event;
        } catch (e) {}
      })
    );
    const filteredLogs = winnersLogs.filter((log) => log);
    const winnersData = filteredLogs.map((log) => {
      const timestampInMilliseconds = Number(log[1]) * 1000;
      const date = new Date(timestampInMilliseconds);
      const isoString = date.toISOString();
      const accountId = AccountId.fromEvmAddress(0,0, log[0]);
      const amount = String(Number(log[2]) / 100_000_000);
      return {
        address: accountId.toString(),
        timestamp: isoString,
        amount: amount,
      };
    });
    return winnersData;
  } catch (e) {
    console.log(`Error: ${e}`);
    return [];
  }
};
