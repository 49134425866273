import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { getEvmAddress, getUserBalance } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import UserBalanceText from "./UserBalanceText";
import Tooltip from "@mui/material/Tooltip";

const UserBalanceDisplay = ({ contractAddress, refreshData }) => {
  const [balance, setBalance] = useState(null);
  const authContext = useContext(AuthContext);
  const { hashAccount } = authContext;
  const theme = useTheme();

  useEffect(() => {
    if (
      hashAccount.accountId !== null &&
      hashAccount.accountId !== "" &&
      hashAccount.accountId !== undefined
    ) {
      const fetchTotalBalance = async () => {
        const evmAddress = await getEvmAddress(hashAccount.accountId);
        const result = await getUserBalance(contractAddress, evmAddress);
        setBalance(result);
      };

      fetchTotalBalance();
    }
  }, [contractAddress, hashAccount.accountId, refreshData]);

  return (
          <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
      <p
        style={{
          fontFamily: theme.fontFamily,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "16px",
          letterSpacing: "0px",
          textAlign: "left",
          color: theme.textDefault,
          marginBottom: 0, // Adjust margin-bottom as needed
        }}
      >
        Your Balance 
        <Tooltip
          title="Your total available balance"
          arrow   
        > 
        <FontAwesomeIcon style={{marginLeft: '8px'}} icon={faInfoCircle} color="#94C008" />
        </Tooltip>
      </p>
        
      <div style={{ marginTop: '8px' }}>
        {balance !== null ? <UserBalanceText balance={`${Number(balance).toFixed(8)}`} /> : "Loading..."}
      </div>
    </div>
  );
};

export default UserBalanceDisplay;
