import {
  Button,
  Stack,
  styled,
  TextField,
  Typography,
  Box,
} from "@mui/material";

function pxToRem(value) {
  return `${value / 16}rem`;
}

export const ContainerStyled = styled(Stack)(({ theme }) => ({
  height: "100%",
}));

export const StackStyled = styled(Stack)(({ theme }) => ({
  margin: ".25rem .5rem",
}));

export const ModalHeaderStack = styled(StackStyled)(({ theme }) => ({
  justifyContent: "space-between",
  padding: 0,
  marginLeft: 0,
}));

export const ErrorInput = styled(TextField)(({ theme }) => ({
  width: "100%",
  color: "black",
  border: "1px solid #888888",
  borderRadius: 5,
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: "black",
  color: "white",
  padding: pxToRem(15),
  width: "100%",
  marginTop: pxToRem(15),
  marginBottom: pxToRem(15),
  textTransform: "capitalize",
  fontSize: pxToRem(18),
  ":hover": {
    color: "black",
    border: "1px solid black",
  },
}));

export const DiscordButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  border: "2px solid black",
  ":hover": {
    backgroundColor: "black",
    color: "white",
  },
}));

export const DirectionText = styled(Typography)(({ theme }) => ({
  color: "#888888",
}));

export const ActionableDirectionText = styled(DirectionText)(({ theme }) => ({
  ":hover": {
    cursor: "pointer",
  },
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  border: `2px solid #94C008`,
  color: "#94C008",
  height: "3rem",
  padding: `${pxToRem(14)} 3rem`,
  width: "6rem",
}));

export const GreyDotStyled = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: "50%",
  height: ".25rem",
  marginRight: pxToRem(4),
  width: ".25rem",
}));

export const TextStyled = styled(Typography)(({ theme }) => ({
  fontSize: pxToRem(12),
  fontWeight: 400,
  lineHeight: "1rem",
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: ".5rem",
  height: "100%",
  left: "50%",
  maxHeight: pxToRem(505),
  maxWidth: pxToRem(885),
  padding: "3rem",
  position: "absolute",
  top: "40%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    borderRadius: 0,
    maxHeight: "1000px",
    padding: "2rem",
    top: "50%",
  },
}));

export const ActionTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: pxToRem(24),
  fontStyle: "normal",
  fontWeight: 800,
}));
