import React, { useContext, useState } from "react";
import { AuthProvider } from "../contexts/HederaContext";
import { AuthContext } from "../contexts/HederaContext";
import WinnersDisplay from "../components/winners-display/WinnersDisplay";
import { useTheme } from "../contexts/ThemeContext";
import WeeklyRewardDisplay from "../components/weekly-reward/WeeklyRewardDisplay";
import UserSharesDisplay from "../components/user-shares-display/UserSharesDisplay";
import PoolDisplay from "../components/pool-display/PoolDisplay";
import { getLottoContractAddress, getWeightedSharesContractAddress } from "../utils/contract-utils";


const HomePage = () => {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  const { hashAccount } = authContext;
  const [refreshData, setRefreshData] = useState(false);

  const toggleRefresh = () => {
    setTimeout(() => {
      setRefreshData((prevRefreshData) => !prevRefreshData);
    }, 2000);
    setRefreshData((prevRefreshData) => !prevRefreshData);
  };

  const lottoAddress = getLottoContractAddress();
  const weightedShareAddress = getWeightedSharesContractAddress();

  return (
    <AuthProvider>
      <style>{`
        html, body {
          margin: 0;
          padding: 0;
          background-color: ${theme.pageBackground};
          min-height: 100%;
          min-width: 350px;
        }

        .container {
          display: flex;
          justify-content: center;
          box-sizing: border-box;
          width: 100%; /* Full width initially */
          padding: 0 12px; /* Default padding */
        }

        .columns-container {
          display: flex;
          flex-wrap: wrap; /* Allow columns to wrap to the next row */
          gap: 24px; /* Gap between columns */
        }

        .column {
          flex: 1;
          width: 100%; /* Full width initially */
          box-sizing: border-box;
        }

        .column > div {
          margin-bottom: 24px; /* Vertical spacing between components */
        }

        .header {
          font-family: ${theme.fontFamily};
          font-size: 40px;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: -1px;
          text-align: left;
          color: #FFFFFF;
          margin-bottom: 64px; 
          margin-top: 84px;
          width: 100%; /* Full width */
        }

      `}</style>

      {/* Media query for smaller screens, adjust padding */}
      <style>{`
        @media (max-width: 767px) {
          .container {
            padding: 0 calc((100vw - 330px) / 2);
          }
        }

        @media (min-width: 768px) {
          .columns-container {
            flex-wrap: nowrap; /* Disable wrapping for larger screens */
          }

          .column:not(:first-child) {
            margin-top: 172px; /* Add margin-top for the second column */
          }
        }
      `}</style>

      <div className="container">
        <div className="columns-container">
          <div className="column">
            <div style={{ width: '100%' }}>
              <div className="header">hbar.cash</div>
              <WeeklyRewardDisplay
                contractAddress={lottoAddress}
                refreshData={refreshData}
              />
              <UserSharesDisplay
                contractAddress={weightedShareAddress}
                userAddress={hashAccount.accountId}
                refreshData={refreshData}
                toggleRefresh={toggleRefresh}
              />
            </div>
          </div>

          <div className="column">
            <div style={{ width: '100%' }}>
              <PoolDisplay contractAddress={lottoAddress} refreshData={refreshData} />
              <div style={{marginTop: '24px' }} /> {/* Margin equal to the header's margin top and bottom */}
              <WinnersDisplay contractAddress={lottoAddress} />
            </div>
          </div>
        </div>
      </div>
    </AuthProvider>
  );
};

export default HomePage;
