// TotalBalance.js
import React, { useEffect, useState } from "react";
import { getTotalBalance } from "../../services/HederaService";
import UserBalanceText from "../user-balance/UserBalanceText";
import { useTheme } from "../../contexts/ThemeContext";

const TotalBalance = ({ contractAddress, refreshData }) => {
  const [balance, setBalance] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchTotalBalance = async () => {
      const result = await getTotalBalance(contractAddress);
      setBalance(result);
    };

    fetchTotalBalance();
  }, [contractAddress, refreshData]);

  return (
    <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start" }}>
<p
  style={{
    fontFamily: theme.fontFamily,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    textAlign: "left",
    color: theme.textDefault,
    marginBottom: 0, // Adjust margin-bottom as needed
  }}
>
  Balance 
</p>
  
<div style={{ marginTop: '8px' }}>
  {balance !== null ? <UserBalanceText balance={`${Number(balance).toFixed(2)}`} /> : "Loading..."}
</div>
</div>)
};

export default TotalBalance;
