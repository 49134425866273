// TotalBalance.js
import React from "react";
import { useTheme } from "../../contexts/ThemeContext";

const EstimatedPrizeBalanceText = ({ balance }) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ fontFamily: theme.fontFamily, fontSize: '24px', fontWeight: 700, lineHeight: '28px', letterSpacing: '0em', textAlign: 'left', color: '#D1D1D1' }}>
        {balance}
      </span>
      <span style={{ fontFamily: theme.fontFamily, fontSize: '12px', fontWeight: 400, lineHeight: '24px', letterSpacing: '-1px', textAlign: 'left', color: '#B2B2B2' }}>
        HBAR
      </span>
    </div>
  );
};

export default EstimatedPrizeBalanceText;
