export const getLottoContractAddress = () => {
    const chainEnv = process.env.REACT_APP_NETWORK ?? "testnet";
    if (chainEnv === "testnet") {
        return process.env.REACT_APP_TESTNET_LOTTORY_POOL_ADDRESS
    } else if (chainEnv === "mainnet") {
        return process.env.REACT_APP_MAINNET_LOTTORY_POOL_ADDRESS
    } else {
        console.log("Unsupported chainEnv selected, defaulting to testnet")
        return process.env.REACT_APP_TESTNET_LOTTORY_POOL_ADDRESS
    }
}


export const getWeightedSharesContractAddress = () => {
    const chainEnv = process.env.REACT_APP_NETWORK ?? "testnet";
    if (chainEnv === "testnet") {
        return process.env.REACT_APP_TESTNET_WEIGHTED_SHARES_ADDRESS
    } else if (chainEnv === "mainnet") {
        return process.env.REACT_APP_MAINNET_WEIGHTED_SHARES_ADDRESS
    } else {
        console.log("Unsupported chainEnv selected, defaulting to testnet")
        return process.env.REACT_APP_TESTNET_WEIGHTED_SHARES_ADDRESS
    }
}