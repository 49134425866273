import React, { useContext } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { useTheme } from "../../contexts/ThemeContext";
import TotalBalanceDisplay from "../total-balance/TotalBalanceDisplay";

const PoolDisplay = ({ contractAddress, refreshData }) => {
  // const [balance, setBalance] = useState(null);
  const authContext = useContext(AuthContext);
  // const { hashAccount } = authContext;

  const theme = useTheme();

  return (
    <div
      style={{
        width: "330px",
        height: "fit-content",
        backgroundColor: theme.cardBackground,
        borderRadius: "10px", // Adjust the radius as needed
        padding: "20px", // Adjust the padding as needed
        boxSizing: "border-box",
      }}
    >
      <h2
        style={{
          color: theme.textDefault,
          fontFamily: theme.fontFamily,
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
          letterSpacing: "-1px",
          textAlign: "left",
        }}
      >
       Pool 
      </h2>
      <TotalBalanceDisplay
        contractAddress={contractAddress}
        refreshData={refreshData}
      />
    </div>
  );
};

export default PoolDisplay;

