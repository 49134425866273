import { HashConnect } from "hashconnect";
import { createContext, useCallback, useMemo, useState } from "react";
import useLocalStorage from "../hooks/use-local-storage";

const NETWORK = process.env.REACT_APP_NETWORK ?? "testnet";

const initialState = {
  associations: [],
  hashAccount: {
    accountId: "",
    accountIds: [],
    topic: "",
    id: "",
    network: "",
    walletMetadata: {
      name: "",
      description: "",
      icon: "",
      publicKey: "",
      url: "",
    },
  },
  onConnect: () => {},
  onDisconnect: () => {},
  setAssociations: () => {},
  appMetadata: () => ({
    name: "Hbar.Cash",
    description: "A fully liquid yield pool",
    icon: "",
  }),
  pairingString: "",
  setHashAccount: {},
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const [hashAccount, setHashAccount] = useLocalStorage(
    "hashAccount",
    initialState.hashAccount
  );
  const [associations, setAssociations] = useLocalStorage(
    "associations",
    initialState.associations
  );
  const [pairingString, setPairingString] = useState("");
  const appMetadata = useCallback(() => {
    return {
      name: "Hbar.Cash",
      description: "A fully liquid yield pool",
      icon: "",
    };
  }, []);

  // const AuthProvider = ({ children }) => {
  //   const [hashAccount, setHashAccount] = useState(
  //     localStorage.getItem("hashAccount") ?? initialState.hashAccount
  //   );
  //   const [associations, setAssociations] = useState(
  //     localStorage.getItem("associations") ?? initialState.associations
  //   );
  //   const [pairingString, setPairingString] = useState("");

  //   const appMetadata = useCallback(() => {
  //     return {
  //       name: "No Lose Lottery",
  //       description: "A fully liquid never lose lottery",
  //       icon: "",
  //     };
  //   }, []);

  const onConnect = useCallback(async () => {
    const hashconnect = new HashConnect();
    await hashconnect.init(appMetadata(), NETWORK, true);
    hashconnect.pairingEvent.on((pairingData) => {
      const { topic, accountIds, metadata, network, id } = pairingData;
      setHashAccount({
        accountId: accountIds[0],
        accountIds,
        id,
        network,
        walletMetadata: metadata,
        topic,
      });
    });
    const state = await hashconnect.connect();
    const pairingString = hashconnect.generatePairingString(
      state,
      NETWORK,
      false
    );
    setPairingString(pairingString);
    hashconnect.connectToLocalWallet();
    hashconnect.findLocalWallets();
  }, [appMetadata, setHashAccount]);

  const updateAssociations = useCallback(
    (value) => setAssociations(value),
    [setAssociations]
  );

  const providerValue = useMemo(
    () => ({
      hashAccount,
      pairingString,
      appMetadata,
      onConnect,
      onDisconnect: () => {
        setHashAccount(null);
        updateAssociations(null);
      },
      associations,
      setHashAccount,
      setAssociations: updateAssociations,
    }),
    [
      hashAccount,
      pairingString,
      appMetadata,
      onConnect,
      associations,
      setHashAccount,
      updateAssociations,
    ]
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
