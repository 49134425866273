import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { Modal, Button, Box, Snackbar, Alert } from "@mui/material";
import { HashConnect } from "hashconnect";
import {
  ContainerStyled,
  StackStyled,
} from "./ConnectButton.style";
import { useTheme } from "../../contexts/ThemeContext";

const ConnectButton = () => {
  const { onConnect, appMetadata, setHashAccount } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [pairingString, setPairingString] = useState("");
  const [copyError, setCopyError] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();
  const chainEnv = process.env.REACT_APP_NETWROK;

  useEffect(() => {
    const configureListener = async () => {
      const hashconnect = new HashConnect();
      await hashconnect.init(appMetadata(), chainEnv, true);
      const state = await hashconnect.connect();
      const pairString = hashconnect.generatePairingString(
        state,
        chainEnv,
        false
      );
      setPairingString(pairString);
      hashconnect.pairingEvent.on((pairingData) => {
        const { topic, accountIds, metadata, network, id } = pairingData;
        setHashAccount({
          accountId: accountIds[0],
          accountIds,
          id,
          network,
          walletMetadata: metadata,
          topic,
        });
      });
    };

    configureListener();
  }, [appMetadata, setHashAccount]);

  const executeCopyAction = async () => {
    try {
      await navigator.clipboard.writeText(pairingString);
      setSnackbarOpen(true);
    } catch (error) {
      setCopyError(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
    setCopyError(false);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: theme.pageBackground,
            p: 4,
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            margin: "0 auto", // Center horizontally
            '@media (max-width: 600px)': {
              width: '80%', // Adjust width for smaller screens
            },
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ mb: 2 }}
            style={{ color: "#262626", background: "#94C008" }}
            onClick={async () => {
              setModalOpen(false);
              onConnect();
            }}
          >
            Connect With Hashpack
          </Button>
          {/* <Button
            onClick={executeCopyAction}
            variant="outlined"
            sx={{ mt: 2 }}
            style={{
              color: "#94C008",
              background: "#262626",
              borderColor: "#94C008",
            }}
          >
            Connect on Mobile
          </Button> */}
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Adjust the duration as needed
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Pairing string copied, now paste it in the hashpack app
        </Alert>
      </Snackbar>

      <ContainerStyled>
        <Button
          onClick={() => setModalOpen(true)}
          sx={{
            fontFamily: theme.fontFamily,
            width: "125px",
            height: "40px",
            padding: "0px 16px 0px 16px",
            borderRadius: "12px",
            gap: "8px",
            backgroundColor: "#94C008",
            color: "#1D1D1D", // Set the text color
          }}
          variant="contained"
          size="large"
        >
          Connect
        </Button>
        <StackStyled
          direction="row"
          alignItems="center"
          justifyContent="end"
        ></StackStyled>
      </ContainerStyled>
    </>
  );
};

export default ConnectButton;
