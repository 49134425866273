// TotalBalance.js
import React, { useEffect, useState } from "react";
import { getRemainingTime } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";

const TimeCountDown = ({ contractAddress }) => {
  const [countdownSeconds, setCountdownSeconds] = useState(null);
  const theme = useTheme();
  useEffect(() => {
    const fetchTimeDifference = async () => {
      const result = await getRemainingTime(contractAddress);
      setCountdownSeconds(result);
    };

    fetchTimeDifference();
  }, [contractAddress]);

  useEffect(() => {
    if (countdownSeconds === null) {
      // If countdownSeconds is still null, return early
      return;
    }

    const interval = setInterval(() => {
      if (countdownSeconds > 0) {
        setCountdownSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    // Cleanup interval on component unmount or when countdown reaches 0
    return () => clearInterval(interval);
  }, [countdownSeconds]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };
  if (countdownSeconds === null) {
    return <div>Loading...</div>;
  }

  const days = Math.max(Math.floor(countdownSeconds / (3600 * 24)), 0);
  const hours = Math.max(Math.floor((countdownSeconds % (3600 * 24)) / 3600),0);
  const minutes = Math.max(Math.floor((countdownSeconds % 3600) / 60), 0);

  return (
    <div
      style={{
        fontFamily: theme.fontFamily,
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "24px",
        letterSpacing: "-1px",
        textAlign: "left",
        color: "#94C008",
      }}
    >
      <div>
        {`${formatTime(days)}d`} {`${formatTime(hours)}h`}{" "}
        {`${formatTime(minutes)}m`}
      </div>
    </div>
  );
};

export default TimeCountDown;
