// import React, { useContext, useState } from "react";

// // Assume you have a context named HashAccountContext
// import { AuthContext } from "../../contexts/HederaContext";
// import { Button, Modal, Box, TextField } from "@mui/material";
// import { WithdrawShares } from "../../services/HederaService";

// const RedeemShares = ({ refreshToggle }) => {
//   // Use the context to get hashAccount.accountId
//   const { hashAccount } = useContext(AuthContext);

//   // State for the modal input field
//   const [numberOfHbar, setNumberOfHbar] = useState("");

//   // State for controlling the modal visibility
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Handler for opening the modal
//   const handleOpenModal = () => {
//     setIsModalOpen(true);
//   };

//   // Handler for closing the modal
//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setNumberOfHbar("");
//     refreshToggle();
//   };

//   // Handler for form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     await WithdrawShares(numberOfHbar, hashAccount);
//     handleCloseModal();
//   };

//   // Disable the button if hashAccount.accountId is undefined
//   const isButtonActive = hashAccount.accountId !== undefined;

//   return (
//     <div>
//       <Button onClick={handleOpenModal} disabled={!isButtonActive}>
//         Redeem Shares
//       </Button>

//       <Modal
//         open={isModalOpen}
//         onClose={handleCloseModal}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 400,
//             bgcolor: "background.paper",
//             p: 4,
//           }}
//         >
//           <form onSubmit={handleSubmit}>
//             <TextField
//               label="Number of Hbar"
//               type="number"
//               value={numberOfHbar}
//               onChange={(e) => setNumberOfHbar(e.target.value)}
//               required
//               fullWidth
//               inputProps={{ min: "1" }}
//             />
//             <Button type="submit" variant="contained" sx={{ mt: 2 }}>
//               Submit
//             </Button>
//           </form>
//           <Button
//             onClick={handleCloseModal}
//             variant="outlined"
//             sx={{ mt: 2, ml: 2 }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </Modal>
//     </div>
//   );
// };

// export default RedeemShares;

import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { Button, Modal, Box, TextField } from "@mui/material";
import { WithdrawShares } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";

const RedeemShares = ({ refreshToggle }) => {
  const { hashAccount } = useContext(AuthContext);

  const [numberOfHbar, setNumberOfHbar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const theme = useTheme();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNumberOfHbar("");
    refreshToggle();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await WithdrawShares(numberOfHbar, hashAccount);
    handleCloseModal();
  };

  const isButtonActive = hashAccount.accountId !== undefined;

  return (
    <div>
      <Button
        onClick={handleOpenModal}
        disabled={!isButtonActive}
        sx={{
          fontFamily: theme.fontFamily,
          width: "125px",
          height: "40px",
          padding: "0px 16px 0px 16px",
          borderRadius: "12px",
          border: "1px solid #94C008",
          gap: "8px",
          color: "#94C008", // Set the text color
          backgroundColor: "transparent", // Set the background color to transparent
          backgroundImage: "none", // Remove the linear gradient background
        }}
      >
        Withdraw
      </Button>

      <Modal
  open={isModalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: theme.pageBackground,
      p: 4,
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      margin: "0 auto", // Center horizontally
      '@media (max-width: 600px)': {
        width: '80%', // Adjust width for smaller screens
      },
    }}
  >
    <form onSubmit={handleSubmit}>
      <TextField
        label="Number of Hbar"
        type="number"
        value={numberOfHbar}
        onChange={(e) => setNumberOfHbar(e.target.value)}
        required
        fullWidth
        inputProps={{ min: "0.00000001", step: "0.00000001", style: { color: "#94C008"} }}
        InputLabelProps={{ style: { color: '#94C008' } }}
        style={{ backgroundColor: "#262626" }}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#94C008', // Set the focused border color
            },
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ mb: 2 }}
        style={{ color: "#262626", background: "#94C008" }}
      >
        Withdraw
      </Button>
    </form>
    <Button
      onClick={handleCloseModal}
      variant="outlined"
      sx={{ mt: 2 }}
      style={{
        color: "#94C008",
        background: "#262626",
        borderColor: "#94C008",
      }}
    >
      Cancel
    </Button>
  </Box>
</Modal>

    </div>
  );
};

export default RedeemShares;
