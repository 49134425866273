import React, { useContext, useState } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { Button, Modal, Box, TextField } from "@mui/material";
import { PurchaseShares } from "../../services/HederaService";
import { useTheme } from "../../contexts/ThemeContext";

const BuyShares = ({ refreshToggle }) => {
  const { hashAccount } = useContext(AuthContext);

  const [numberOfHbar, setNumberOfHbar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setNumberOfHbar("");
    refreshToggle();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await PurchaseShares(numberOfHbar, hashAccount);
    handleCloseModal();
  };

  const isButtonActive = hashAccount.accountId !== undefined;

  return (
    <div>
      <Button
        onClick={handleOpenModal}
        disabled={!isButtonActive}
        sx={{
          fontFamily: theme.fontFamily,
          width: "125px",
          height: "40px",
          padding: "0px 16px 0px 16px",
          borderRadius: "12px",
          gap: "8px",
          backgroundColor: "#94C008",
          color: "#1D1D1D", // Set the text color
        }}
        variant="contained"
        size="large"
      >
        Deposit
      </Button>
      <Modal
  open={isModalOpen}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: theme.pageBackground,
      p: 4,
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      margin: "0 auto", // Center horizontally
      '@media (max-width: 600px)': {
        width: '80%', // Adjust width for smaller screens
      },
    }}
  >
    <form onSubmit={handleSubmit}>
      <TextField
        label="Number of Hbar"
        type="number"
        value={numberOfHbar}
        onChange={(e) => setNumberOfHbar(e.target.value)}
        required
        fullWidth
        inputProps={{ min: "1", step: "0.00000001", style: { color: "#94C008"} }}
        InputLabelProps={{ style: { color: '#94C008' } }}
        style={{ backgroundColor: "#262626" }}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#94C008', // Set the focused border color
            },
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        sx={{ mb: 2 }}
        style={{ color: "#262626", background: "#94C008" }}
      >
        Deposit
      </Button>
    </form>
    <Button
      onClick={handleCloseModal}
      variant="outlined"
      sx={{ mt: 2 }}
      style={{
        color: "#94C008",
        background: "#262626",
        borderColor: "#94C008",
      }}
    >
      Cancel
    </Button>
  </Box>
</Modal>

    </div>
  );
};

export default BuyShares;
