import React, { useEffect, useState, useContext } from "react";
import { PickWinner, getRecentWinners } from "../../services/HederaService";
import { AuthContext } from "../../contexts/HederaContext";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Button
} from "@mui/material";
import WinnerDisplayRow from "./WinnerDisplayRow";
import { useTheme } from "../../contexts/ThemeContext";


const WinnersDisplay = ({ contractAddress }) => {
  const authContext = useContext(AuthContext);
  // const { hashAccount } = authContext;
  const {hashAccount} = authContext;
  const columns = [
    { key: "address", header: "Winner Address" },
    { key: "amount", header: "Hbar Won" },
  ];
  const [winnersData, setWinnersData] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const fetchRecentWinners = async () => {
      const result = await getRecentWinners(contractAddress);
      setWinnersData(result);
    };

    fetchRecentWinners();
  }, [contractAddress]);

  return (
    <div
      style={{
        width: "330px",
        height: "fit-content",
        backgroundColor: theme.cardBackground,
        borderRadius: "10px",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <h2
        style={{
          color: theme.textDefault,
          fontFamily: theme.fontFamily,
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
          letterSpacing: "-1px",
          textAlign: "left",
        }}
      >
        Recent Winners
      </h2>
      {(hashAccount.accountId === '0.0.2654461' || hashAccount.accountId === '0.0.4330712') && <Button  onClick={() => PickWinner(hashAccount)}>
        Pick Winner
      </Button>}
      <TableContainer
        component={Paper}
        style={{
          background: "#262626",
          border: "none", // Remove border
          boxShadow: "none", // Remove elevation
        }}
      >
        <Table>
          <TableBody>
            {winnersData.map((row, rowIndex) => (
              <WinnerDisplayRow
                key={rowIndex}
                winnerAddress={row.address}
                winnerAmount={row.amount}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WinnersDisplay;