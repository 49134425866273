import React, { useContext } from "react";
import { AuthContext } from "../../contexts/HederaContext";
import { useTheme } from "../../contexts/ThemeContext";
import UserBalanceDisplay from "../user-balance/UserBalanceDisplay";
import BuyShares from "../buy-shares/BuyShares";
import RedeemShares from "../redeem-shares/RedeemShares";
import ConnectButton from "../connect-button/ConnectButton";

const UserSharesDisplay = ({ contractAddress, refreshData, toggleRefresh }) => {
  // const [balance, setBalance] = useState(null);
  const authContext = useContext(AuthContext);
  // const { hashAccount } = authContext;
  const {hashAccount} = authContext;
  const theme = useTheme();

  return (
    <div
      style={{
        width: "330px",
        height: "fit-content",
        backgroundColor: theme.cardBackground,
        borderRadius: "10px", // Adjust the radius as needed
        padding: "20px", // Adjust the padding as needed
        boxSizing: "border-box",
        fontFamily: theme.fontFamily,
      }}
    >
      <h2
        style={{
          color: theme.textDefault,
          fontSize: "24px",
          fontWeight: 700,
          lineHeight: "28px",
          letterSpacing: "-1px",
          textAlign: "left",
          fontFamily: theme.fontFamily,
        }}
      >
        User Balance
      </h2>
      {hashAccount.accountId !== "" ? (
        <>
          <UserBalanceDisplay
            contractAddress={contractAddress}
            refreshData={refreshData}
          />
          <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <RedeemShares refreshToggle={toggleRefresh} />
            <BuyShares refreshToggle={toggleRefresh} />
          </div>
        </>
      ) : (
        <ConnectButton />
      )}
    </div>
  );
};

export default UserSharesDisplay;

